<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between">
      <div class="truncate">
        <h6 class="mb-2">{{ title }}</h6>
        <h3 class="mb-25 font-weight-bolder" v-if="users">{{ (stat / users).toFixed(2) }}</h3>
        <h3 class="mb-25 font-weight-bolder" v-else-if="stat !== undefined">{{ stat % 1 === 0 ? stat : stat.toFixed(2) }}</h3>
        <span v-if="sub_text === 'empty'"></span>
        <span v-else-if="sub_text">{{ sub_text }}</span>
        <span v-else-if="users">Avg Per User</span>
        <span v-else>Per Activity</span>
      </div>
      <div class="truncate text-right">
        <b-badge class="mb-2 text-right" :variant="previous_stat > stat ? 'danger' : 'success'">
          {{ calculatePercentage(previous_stat, stat).toFixed(2) }}%
        </b-badge>
        <h3 class="mb-25 font-weight-bolder" v-if="users">{{ stat }}</h3>
        <span v-if="users">Total</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BBadge } from 'bootstrap-vue'
export default {
  components: {
    BCard,
    BCardBody,
    BBadge,
  },
  props: ['title', 'users', 'stat', 'previous_stat', 'sub_text'],
  methods: {
    calculatePercentage(from, to) {
      let divider = from < to ? to : from
      if (divider) {
        return ((to - from) / divider) * 100
      } else {
        return to * 100
      }
    },
  },
}
</script>

<style></style>
