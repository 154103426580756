<template>
  <section class="pb-2">
    <users />
    <demo />
    <consultation />
    <request-country />
  </section>
</template>

<script>
import Users from './Users.vue'
import Demo from './Demo.vue'
import Consultation from './Consultation.vue'
import RequestCountry from './RequestCountry.vue'

export default {
  components: {
    Users,
    Demo,
    Consultation,
    RequestCountry,
  },
}
</script>

<style></style>
